import React, { useContext, Suspense } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { CircularProgress, Box } from '@mui/material';
import { UserContext } from './contexts/UserContext';

const LandingPage = React.lazy(() => import('./pages/landing/LandingPage'));
const DashboardPage = React.lazy(
  () => import('./pages/dashboard/DashboardPage')
);
const NewReportPage = React.lazy(
  () => import('./pages/newreport/NewReportPage')
);
const ReportPage = React.lazy(() => import('./pages/report/ReportPage'));
const TosPage = React.lazy(() => import('./pages/TosPage'));
const PrivacyPolicyPage = React.lazy(() => import('./pages/PrivacyPolicyPage'));
const AboutUsPage = React.lazy(() => import('./pages/AboutUsPage'));
const ContactPage = React.lazy(() => import('./pages/ContactPage'));

const App: React.FC = () => {
  const { user, isAuthLoading } = useContext(UserContext);
  const location = useLocation();

  if (isAuthLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {user || location.pathname !== '/' ? <Header /> : null}
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="50vh"
            >
              <CircularProgress />
            </Box>
          }
        >
          <Routes>
            <Route
              path="/"
              element={user ? <Navigate to="/dashboard" /> : <LandingPage />}
            />
            <Route path="/tos" element={<TosPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/about" element={<AboutUsPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route
              path="/dashboard"
              element={user ? <DashboardPage /> : <Navigate to="/" />}
            />
            <Route
              path="/new-report"
              element={user ? <NewReportPage /> : <Navigate to="/" />}
            />
            <Route
              path="/new-report/:reportId?"
              element={user ? <NewReportPage /> : <Navigate to="/" />}
            />
            <Route
              path="/report/:reportId"
              element={user ? <ReportPage /> : <Navigate to="/" />}
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
      </Box>
      <Footer />
    </Box>
  );
};

export default App;
